import { BlogImage } from "../../assets";
import { BlogSection, TopBanner } from "../../componets";
import { Page } from "../../layout";

export const Blog = () => {
  return (
    <Page>
      <TopBanner bannerImage={BlogImage} sectionsName={"About us"} />
      <div className="bg-white">
        <BlogSection />
      </div>
    </Page>
  );
};
