import { useParams } from "react-router-dom";
import { Page } from "../../layout";
import { useEffect, useState } from "react";
import { blogData } from "../../constant";
import { MetaTags, TopBanner } from "../../componets";

export const BlogPage = () => {
  const { id } = useParams();
  const [selectedBlog, setSelectedBlog] = useState();
  const [ogValue, setOgValue] = useState();

  useEffect(() => {
    const blog = blogData.filter((item) => item?.id == id);
    setSelectedBlog(blog);
  }, [id]);

  useEffect(() => {
    const selectedBlog = blogData.find((item) => item?.id === Number(id));
    setOgValue(selectedBlog);
  }, [id]);

  const imageUrl =
    id === 1
      ? "https://ripeadvisory.com.au/static/media/blog1.6f60f70f4969699386ca.png"
      : id === 2
      ? "https://ripeadvisory.com.au/static/media/blog2.0f8e86d40efad0415868.png"
      : "https://ripeadvisory.com.au/static/media/blog3.3d5769203aa7a484758d.png";

  return (
    <>
      <MetaTags
        title={ogValue?.heading}
        description={ogValue?.about}
        imageUrl={imageUrl}
      />
      <Page>
        <div className="w-11/12 tablet:w-10/12  mx-auto py-8 tablet:py-16">
          {selectedBlog?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-col font-poppins gap-y-4 w-full desktop:w-4/5 mx-auto items-start"
              >
                <div className="flex w-full border-4 p-2 rounded-lg border-solid border-primary">
                  <img
                    src={item.image}
                    alt="blog"
                    className="w-full rounded-md"
                    loading="lazy"
                  />
                </div>
                <div className="text-xl md:text-2xl text-start font-semibold mt-4">
                  {item?.heading}
                </div>
                <div className="text-base text-start">{item?.about}</div>
                <div className="flex flex-col gap-y-4 text-start items-start justify-start">
                  {item?.discription?.map((dis, i) => {
                    if (typeof dis === "string") {
                      return <div className="text-base">{dis}</div>;
                    } else if (typeof item === "object") {
                      return (
                        <div key={i} className="">
                          <div className="text-lg font-semibold mb-2">
                            {dis?.headingName}
                          </div>
                          <div className="text-base">
                            {dis?.Aboutdiscription}
                          </div>
                          <div className="flex flex-col gap-y-4 mt-4">
                            {dis?.listingData &&
                              dis?.listingData?.map((list, i) => {
                                return (
                                  <div key={i} className="pl-4">
                                    <div className="text-base font-semibold mb-1">
                                      {i + 1}. {list?.name}
                                    </div>
                                    <div className="text-base">
                                      {list?.about}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Page>
    </>
  );
};
