import React from "react";
import {
  ContactCard,
  Estimate,
  OurFeature,
  Ripeadvisory,
  TopBanner,
} from "../../componets";
import { aboutBanner } from "../../assets";
import { Page } from "../../layout";

export const AboutPage = () => {
  return (
    <Page>
      <TopBanner bannerImage={aboutBanner} sectionsName={"About us"} />
      <div className="bg-white">
        <Ripeadvisory />
        <OurFeature />
        <Estimate />
        <ContactCard />
      </div>
    </Page>
  );
};
