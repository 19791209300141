import {
  Approch,
  Blog1,
  Blog2,
  Blog3,
  Google,
  Mission,
  Team,
  locationicon,
  phoneicon,
  watchicon,
  service_1,
  service_2,
  service_3,
  service_4,
  service_5,
  service_6,
  service_7,
  service_8,
  service_9,
  service_10,
  Mail,
} from "../assets";

export const selectOptions = [
  "Project",
  "Portfolio",
  "Asset",
  "Risk",
  "Contract administration",
  "Change & transformation",
  "Business & Reengineering",
  "Information & knowledge",
  "Organisation & corporate",
  "Strategy & implementation",
];

export const featureData = [
  {
    image: Mission,
    heading: "Our Mission",
    discription:
      "At RIPE Advisory, our mission is to help organisations achieve sustainable growth and success by providing them with expert business and management advisory services.",
  },
  {
    image: Approch,
    heading: "Our Approach",
    discription:
      "We take a holistic approach to advisory, focusing on every aspect of a business's operations to identify areas for improvement and growth. ",
  },
  {
    image: Team,
    heading: "Our Team",
    discription:
      "Our team consists of highly experienced practitioners with diverse backgrounds and areas of expertise. We are passionate about helping organisations succeed and are committed to delivering exceptional service to our clients.",
  },
];

export const testimonialsData = [
  {
    icon: Google,
    star: 5,
    name: "Ujjol Rahman",
    discription:
      "Professional, empathetic and efficient! Transformed my SOS chipped front and completely rejuvenated both my smile and confidence swiftly! Whole team was friendly too.",
  },
  {
    icon: Google,
    star: 5,
    name: "Gideon Hyde",
    discription:
      "I have been going to KDC and Marie for 10 years with my family. Marie provides brilliant dental care and, even better, wider health advice. Highly recommend.",
  },
  {
    icon: Google,
    star: 5,
    name: "Caroline Mcginn",
    discription:
      "Excellent and caring dentist whose work is outstanding and who are wonderfully kind to nervous patients. I’ve never felt so comfortable in a dentist chair. Not cheap but worth every penny.        ",
  },
  {
    icon: Google,
    star: 5,
    name: "Ujjol Rahman",
    discription:
      "Professional, empathetic and efficient! Transformed my SOS chipped front and completely rejuvenated both my smile and confidence swiftly! Whole team was friendly too.",
  },
  {
    icon: Google,
    star: 5,
    name: "Gideon Hyde",
    discription:
      "I have been going to KDC and Marie for 10 years with my family. Marie provides brilliant dental care and, even better, wider health advice. Highly recommend.",
  },
  {
    icon: Google,
    star: 5,
    name: "Caroline Mcginn",
    discription:
      "Excellent and caring dentist whose work is outstanding and who are wonderfully kind to nervous patients. I’ve never felt so comfortable in a dentist chair. Not cheap but worth every penny.        ",
  },
  {
    icon: Google,
    star: 5,
    name: "Ujjol Rahman",
    discription:
      "Professional, empathetic and efficient! Transformed my SOS chipped front and completely rejuvenated both my smile and confidence swiftly! Whole team was friendly too.",
  },
  {
    icon: Google,
    star: 5,
    name: "Gideon Hyde",
    discription:
      "I have been going to KDC and Marie for 10 years with my family. Marie provides brilliant dental care and, even better, wider health advice. Highly recommend.",
  },
  {
    icon: Google,
    star: 5,
    name: "Caroline Mcginn",
    discription:
      "Excellent and caring dentist whose work is outstanding and who are wonderfully kind to nervous patients. I’ve never felt so comfortable in a dentist chair. Not cheap but worth every penny.        ",
  },
];

export const blogData = [
  {
    id:1,
    image: Blog1,
    name: "Anticipating the Future",
    date: "24 february 2024",
    heading:
      "Anticipating the Future: The Crucial Role of Foresight in Project Management",
    about:
      "In the realm of project management, the concept of 'foresight' holds paramount importance, capturing the ability to predict forthcoming events or anticipate future needs.",
    discription: [
      "A question often arises: What sets exceptional project managers apart from their proficient counterparts? Beyond mastering communication skills and becoming adept with a suite of tools, the differentiator lies in the application of foresight — a skill that can only be honed through lived experiences. This includes learning from failure or failing forward as it’s sometimes referred to. For some important lessons, this is the only way to learn. Armed with foresight, the project manager is able to manage and mitigate risks well before they materialise.",
      "In this context, it is paramount to underscore the pivotal role that organisations play in nurturing the cultivation of underlying experiential knowledge. As project managers navigate the ever-evolving landscape of their multifaceted responsibilities, the ability to foresee challenges and opportunities emerges as a defining factor in achieving excellence in their roles.",
      "Recognising the symbiotic relationship between foresight and organisational culture, it becomes imperative to create an environment that fosters continuous learning and embraces the valuable insights gained from both successes and failures.",
      "Successful project organisations recognise the journey from proficiency to excellence in project management involves not just the acquisition of skills but the development of a foresight-driven mindset. Armed with the ability to anticipate future trends and needs, project managers emerge as visionary leaders, steering projects toward success and contributing to the organisational resilience required in an ever-changing landscape.",
    ],
  },
  {
    id:2,
    image: Blog2,
    name: "Cultivating a Risk-Intelligent Culture",
    date: "28 March, 2024",
    heading:
      "Cultivating a Risk-Intelligent Culture: The Foundation of Effective Risk Management",
    about:
      "In the intricate dance of organisational dynamics, risk management emerges as a vital choreographer, orchestrating the steps that lead to sustained success. However, a critical stumbling block often arises at the very beginning of this dance—the reluctance of organisations to readily recognise and acknowledge their risks. In this blog, we delve into the importance of fostering a culture that not only embraces risk identification but thrives on it, recognising it as the pivotal first step towards effective risk management.",
    discription: [
      {
        headingName: "The Uncomfortable Truth",
        Aboutdiscription:
          "Acknowledging vulnerabilities can be uncomfortable, and organisations, like individuals, may be inclined to turn a blind eye to potential risks. This reluctance often stems from a fear of tarnishing the organisation's image, unsettling stakeholders, or even facing the daunting task of navigating uncertainties. However, the uncomfortable truth is that risks, when left unacknowledged, have the potential to escalate into crises that could have otherwise been averted.",
      },
      {
        headingName: "Identification",
        Aboutdiscription:
          "Effective risk management hinges on the crucial first step of identifying potential risks. This involves a proactive and systematic process of recognising uncertainties, vulnerabilities, and potential disruptions that could impact the achievement of organisational objectives. Identifying risks sets the stage for informed decision-making and strategic planning, allowing organisations to navigate uncertainties with resilience.",
      },
      {
        headingName: "Facilitating a Risk-Intelligent Culture",
        Aboutdiscription:
          "Organisations can actively work towards fostering a culture that not only encourages but celebrates the identification of risks. Here's why:",
        listingData: [
          {
            name: "Proactive Problem Solving",
            about:
              "A culture that values risk identification promotes proactive problem-solving. It encourages employees at all levels to bring potential risks to the forefront. This fosters a culture in which challenges are met with collective problem-solving rather than reactive firefighting.",
          },
          {
            name: "Strategic Decision-Making",
            about:
              "Recognising and understanding risks enables organisations to make more strategic and informed decisions. It allows for the development of contingency plans, ensuring that the organisation is well-prepared to navigate uncertainties and capitalise on opportunities.",
          },
          {
            name: "Continuous Learning",
            about:
              "Embracing a culture of risk identification promotes a mindset of continuous learning. It encourages reflection on past experiences, both successes, and failures, leading to the refinement of risk management strategies and the cultivation of a learning organisation.",
          },
          {
            name: "Stakeholder Trust",
            about:
              "Transparently acknowledging risks fosters trust among stakeholders. Open communication about potential challenges demonstrates organisational honesty and integrity, strengthening relationships with clients, investors, and the wider community.",
          },
          {
            name: "Innovation and Growth",
            about:
              "A culture that recognises risks as inherent to growth encourages innovation. It empowers employees to explore new ideas and initiatives while acknowledging there may be potential risks and that these can be identified and managed effectively.",
          },
        ],
      },
      {
        headingName: "Conclusion",
        Aboutdiscription:
          "In the dynamic landscape of modern business, where uncertainties are inevitable, embracing a risk-intelligent culture is not just beneficial; it is imperative. Organisations that actively encourage the identification and management of risks, position themselves as resilient and adaptive entities. The journey towards effective risk management begins with the acknowledgment that risks exist and the commitment to building a culture that turns this acknowledgment into a strategic advantage.",
      },
    ],
  },
  {
    id:3,
    image: Blog3,
    name: "The Lifecycle Symphony",
    date: "10 April, 2024",
    heading:
      "The Lifecycle Symphony: The Significance of Holistic Asset Management in Social Infrastructure",
    about:
      "As governments seek to provide social infrastructure that is fit for purpose, safe and compliant, the journey from conception to completion is not just about the construction of tangible structures but involves a meticulous array of planning, procurement, maintenance, renewal and eventual decommissioning and disposal. Understanding the entirety of this journey is crucial, and that's where the concept of whole-of-lifecycle asset management is key. We shed light on why viewing infrastructure assets through the lens of their entire lifecycle is not just beneficial but imperative.",
    discription: [
      {
        headingName: "The Lifecycle Symphony",
        Aboutdiscription:
          "Understanding the full spectrum of costs involves embracing the concept of whole-of-lifecycle asset management. This holistic approach recognises that an asset's journey doesn't end with construction; it spans its entire lifespan, from ‘cradle to grave’.",
        listingData: [
          {
            name: "Acquisition (Capital Costs)",
            about:
              "The initial investment to acquire and construct infrastructure assets is undoubtedly significant. However, it's crucial to recognise that this is just the initial set of costs.",
          },
          {
            name: "Maintenance",
            about:
              "As assets age, the need for upkeep becomes paramount. Regular maintenance ensures the longevity and performance of the asset as well as its safety and compliance, however, it also prevents the escalation of repair and replacement costs over time.",
          },
          {
            name: "Preservation",
            about:
              "Preserving the functionality and relevance of infrastructure assets often involves strategic interventions, whether it's upgrading technology, enhancing efficiency, or adapting to evolving standards and regulations.",
          },
          {
            name: "Disposal",
            about:
              "At some stage, infrastructure assets reach the end of their lifecycle. Proper disposal, whether through decommissioning or repurposing, is a crucial phase that demands careful consideration.",
          },
        ],
      },
      {
        headingName: "The Four-Fifths Reality",
        Aboutdiscription:
          "By embracing whole-of-lifecycle asset management, organisations gain a comprehensive understanding of the true cost landscape. Upfront capital costs may initiate the infrastructure journey, but the subsequent four-fifths in capital and operational costs—comprising maintenance, preservation, and disposal—are crucial in the overall provision of service from those assets. Ignoring these lifecycle costs can lead to inefficiencies, unexpected expenses, unsafe or uncompliant assets, and a failure to maximise the value of infrastructure investments",
      },
      {
        headingName: "Conclusion",
        Aboutdiscription:
          "In the realm of infrastructure building, acknowledging the full lifecycle of assets is not just a prudent approach; it's a strategic imperative. By recognising that initial capital costs represent only a fraction of the total expenditure, organisations can proactively plan for the maintenance, preservation, and eventual disposition of assets. Whole-of-lifecycle asset management ensures that infrastructure assets deliver sustained value and longevity to the communities and governments they serve.",
      },
    ],
  },
];

export const faqs = [
  {
    title: "I have a short-term project requirement. Can you help?",
    body: "We've strict data protection policies in place, which ensure the complete safety and privacy of your data. We work in our office – under a completely controlled environment of encrypted servers and monitored network – and have teams that are bound by the terms of non-disclosure, outlined in our terms of service.",
  },
  {
    title: "Do you sign an NDA?",
    body: "We've strict data protection policies in place, which ensure the complete safety and privacy of your data. We work in our office – under a completely controlled environment of encrypted servers and monitored network – and have teams that are bound by the terms of non-disclosure, outlined in our terms of service.",
  },
  {
    title: "What is your project delivery process?",
    body: "We've strict data protection policies in place, which ensure the complete safety and privacy of your data. We work in our office – under a completely controlled environment of encrypted servers and monitored network – and have teams that are bound by the terms of non-disclosure, outlined in our terms of service.",
  },
  {
    title: "What is the cost of your Mechanical CAD Drafting Services?",
    body: "We've strict data protection policies in place, which ensure the complete safety and privacy of your data. We work in our office – under a completely controlled environment of encrypted servers and monitored network – and have teams that are bound by the terms of non-disclosure, outlined in our terms of service.",
  },
  {
    title: "How can I ensure your services are right for my project?",
    body: "We've strict data protection policies in place, which ensure the complete safety and privacy of your data. We work in our office – under a completely controlled environment of encrypted servers and monitored network – and have teams that are bound by the terms of non-disclosure, outlined in our terms of service.",
  },
];

export const contactData = [
  {
    icon: phoneicon,
    title: "Business Phone",
    content1: "Phone: +61 458 601 533",
    link: "tel:+61458601533",
  },
  {
    icon: Mail,
    title: "Business Email",
    content1: "info@ripeadvisory.com ",
    link: "mailto:info@ripeadvisory.com",
  },
  {
    icon: watchicon,
    title: "Opening Hours",
    content1: "From Monday – Friday",
    content2: "9 am to 6 pm",
  },
  {
    icon: locationicon,
    title: "Postal Address",
    content1: "Po.Box 730 Turramurra",
    content2: "NSW 2074",
  },
];

export const solutionCardDetail = [
  {
    id: "ProgramManagement",
    heading: "Project / Program Management",
    image: service_1,
    content: [
      "Our comprehensive advisory services in project and program management are designed to guide every phase of the engineering project lifecycle. We are typically involved from the project's inception, ensuring the establishment of a robust foundation for project teams. This includes implementing project control mechanisms and governance structures to guarantee a solid framework.",
      "Whether engaged during project initiation, planning, execution, or monitoring, our experts utilise industry-leading methodologies to optimise resource utilisation, mitigate risks, and establish projects for success, ensuring timely delivery within budgetary constraints.",
      "In instances where projects encounter challenges, we provide advisory support to realign them on the path to success. Drawing from extensive experience across diverse multi-sectoral capital projects and non-capital programs, we possess the foresight to identify potential issues in project environments well before they manifest. This enables us to ask pertinent questions and recommend timely corrective actions.",
      "Our expertise spans a wide spectrum, covering everything from small-scale projects to large, intricate programs. We pride ourselves on delivering tailored solutions that address the unique needs of each client, ensuring their projects are managed effectively and achieve their objectives.",
    ],
  },
  {
    id: "PortfolioManagement",
    heading: "Portfolio Management",
    image: service_2,
    content: [
      "Unleash the full potential of your organisation through our portfolio management advisory services. We specialise in aligning projects and programs with strategic objectives, optimising resource allocation, and maximising returns on investment.",
      "Our portfolio management experts take pride in their ability to identify efficiencies and channel them towards improving financial performance. Whether enhancing operational efficiencies or optimising long-term lifecycle costs, we excel in balancing costs and benefits within a portfolio while upholding high compliance standards and safety margins. Leveraging cutting-edge tools in artificial intelligence and data analytics, we uncover opportunities for cost savings and improved portfolio performance, providing valuable insights for financial and operational enhancements.",
      "Collaborating closely with clients, our consultants craft a well-balanced and prioritised portfolio that aligns seamlessly with the organisation's goals.",
    ],
  },
  {
    id: "asset Management",
    heading: "Asset Management",
    image: service_3,
    content: [
      "Our dedicated asset management advisory services are tailored to assist organisations in maximising the efficiency of both their physical and digital assets. We focus on enhancing asset regulatory compliance and elevating safety margins to ensure the secure operational performance of assets.",
      "Whether it's at the policy, strategic, tactical, or operational level, we offer a comprehensive suite of asset management services. Our goal is to equip clients to operate safely, meet compliance standards, and optimise their assets effectively.",
      "From organisational design and transformation to meticulous physical asset maintenance planning and precise asset tracking, we cover all facets of the asset management system. As experts in data and information management, we also provide services to improve asset registers, laying a robust foundation for analytics and decision support.",
      "We stand ready to provide insights and innovative solutions aimed at improving asset performance, reducing operational costs, and extending asset lifecycles. Your organisation can rely on our expertise to navigate the complexities of asset management successfully.",
    ],
  },
  {
    id: "riskManagement",
    heading: "Risk Management",
    image: service_4,
    content: [
      "Within the dynamic landscape of risk management, our advisory services offer an extensive array of solutions designed to empower your organisation in navigating potential threats. Our services are crafted to assist clients in a meticulous exploration of their organisational landscape, identifying, assessing, and mitigating risks across diverse business domains. We specialise in delving into intricate organisational structures, projects, or programs, uncovering risks that may elude self-identification within the organisation.",
      "We bring an added dimension to risk management by facilitating board sessions where we collaboratively determine risk appetites. Working closely with management, we foster a shared understanding of risks and develop innovative strategies to manage them effectively. This collaborative approach ensures a comprehensive perspective, capturing nuances that may be overlooked in a traditional risk assessment.",
      "Going beyond conventional risk management frameworks, our experts craft bespoke strategies tailored to align seamlessly with your organisational objectives. Through proactive risk management initiatives, we empower your organisation to cultivate resilience, enabling you to confidently navigate uncertainties and make well-informed decisions within the dynamic and ever-evolving business environment.",
    ],
  },
  {
    id: "contractAdministration",
    heading: "Contract Administration",
    image: service_5,
    content: [
      "Dive into the intricacies of contract management with our specialised services, where we transform complex commercial landscapes into a streamlined and strategic process. This process maintains the requisite rigor while allowing for practical implementation. From the initiation phase through design development and delivery, compliance monitoring, and variations, our comprehensive support spans the entirety of the contract lifecycle.",
      "Our seasoned advisors bring a wealth of expertise and hands-on project experience, ensuring that your contracts are not just legal documents but strategically structured frameworks that seamlessly align with project parameters. We go beyond the conventional approach, providing assistance in contract management focussed on flawless execution of projects from day one. Our collaborative effort contributes significantly to achieving successful project outcomes and ensuring client satisfaction at every stage of the contractual journey.",
    ],
  },
  {
    id: "changeTransformation",
    heading: "Change And Transformation Management",
    image: service_6,
    content: [
      "In the domain of Change and Transformation Management, we distinguish ourselves by adeptly navigating organisations through dynamic shifts. Our expansive suite of services encompasses the entire spectrum of change, spanning from incremental, continuous improvement programs to major, radical, organisation-wide transformations. Commencing with the initiation of your organisation's change or transformation program, we engage in a diagnostic assessment, facilitating the crafting of a vision and strategy meticulously aligned with organisational objectives.",
      "Our paramount focus revolves around cultivating a change-ready culture within organisations, fostering adaptability in response to evolving market dynamics. By emphasising leadership alignment and cultural mapping, we empower our clients not merely to navigate change successfully but to thrive in dynamic business environments where change is a perpetual reality.",
      "We extend support throughout structural and process changes, skill development and training initiatives, as well as through the execution of pilot programs and prototypes before full-scale implementation. Our commitment to ongoing support extends to continuous improvement, ensuring that our clients are equipped to adapt to evolving challenges and opportunities.",
    ],
  },
  {
    id: "businessSystems",
    heading: "Business / Systems / Process Analysis / Reengineering",
    image: service_7,
    content: [
      "Specialising in the optimisation of business operations, our firm excels in Business, Systems, and Process Analysis and Reengineering. We embark on comprehensive assessments to identify inefficiencies and bottlenecks, offering strategic recommendations that enhance productivity and reduce operational costs. Whether streamlining processes, implementing innovative systems, or reengineering workflows, our solutions are meticulously tailored to align with organisational goals, ensuring sustained efficiency and competitiveness in a dynamic business environment.",
      "Our team of skilled system analysts possesses the expertise to map existing processes according to your organisation's unique business practices. Through a meticulous analysis of the desired outcomes and outputs of your business operations, we craft optimal processes and system elements to ensure heightened efficiency and delivery. Our approach aims to instil a systems mindset within your organisation, empowering personnel to critically evaluate each new workflow or component delivery for maximum effectiveness. This proactive mindset contributes to a culture of continuous improvement, ensuring that your organisation remains agile and adaptive in an ever-evolving business environment.",
    ],
  },
  {
    id: "information",
    heading: "Information And Knowledge Management",
    image: service_8,
    content: [
      "At the heart of our Information and Knowledge Management services is the unlocking of the full potential of your information assets. Our offerings range from robust data governance strategies to ensuring information security, with our consultants crafting solutions that empower organisations to make decisions confidently based on reliable information. Our Knowledge Management experts excel in designing strategies to effectively maintain, disseminate, and apply knowledge resources within the organisation, adding substantial value toward achieving organisational objectives.",
      "We specialise in seeding discipline-specific communities of practice, fostering collaboration among professionals, and applying cutting-edge technologies to facilitate enhanced shared understanding among organisational groups. By leveraging our expertise, organisations can harness the power of their information and knowledge to drive innovation, strategic growth, and informed decision-making.",
    ],
  },
  {
    id: "organisationalLearning",
    heading: "Organisational Learning And Corporate Memory Development",
    image: service_9,
    content: [
      "Our expertise lies in guiding organisations to cultivate a culture of continuous learning and knowledge retention through our Organisational Learning and Corporate Memory Development services. We not only design and implement learning programs but also create knowledge-sharing platforms and systems dedicated to capturing and preserving organisational knowledge.",
      "As projects and programs conclude, we offer specialised services to ensure that valuable lessons are not only captured and understood but also integrated into the organisational fabric. This integration prevents the repetition of mistakes on subsequent initiatives, contributing to a culture of continuous improvement.",
      "By fostering a culture of learning, our services empower organisations to adapt, innovate, and remain competitive in an ever-evolving business environment. Our approach ensures that institutional knowledge becomes a driving force for sustained success and excellence.",
    ],
  },
  {
    id: "strategyDevelopment",
    heading: "Strategy Development And Implementation",
    image: service_10,
    content: [
      "In the arena of Strategy Development and Implementation, our collaboration with organisations is characterised by a close partnership in formulating robust, actionable strategies aligned seamlessly with organisational goals. Engaging in focused discussions and planning sessions with board members and executive teams, we ensure that the resulting strategic plan goes beyond mere compliance with organisational requirements. Instead, it emerges as a dynamic instrument guiding the executive team to deliver, consistently measure performance, and adapt to evolving circumstances.",
      "Our commitment extends throughout the entire implementation process, where we guide organisations to ensure the effective execution, continuous monitoring, and adaptation of strategies to address changing market conditions. By delivering strategic clarity and direction, we empower organisations not only to achieve sustainable growth but also to maintain a competitive edge.",
    ],
  },
];

export const aboutRipe = {
  content: [
    "Welcome to RIPE, where our philosophy is about finding optimal solutions to business problems.  With years of experience across a large range of industries, we’ve learnt the critical importance of accurately defining business problems, steering clear of crafting brilliant solutions for the wrong issues. Consequently, we invest time in identifying, comprehending, and validating the genuine business problems that demand attention.",
    "Our approach involves the meticulous pairing of the right expertise with our clients' specific needs. We acknowledge the inherent value of internal team members and complement their insights by collaborating with external experts. This symbiotic partnership brings together the intricate details and a fresh, outsider perspective, creating an ideal environment for developing innovative solutions.",
    "At RIPE, we place high value on the rigour of our deliverables, rooted in sound theoretical foundations. We also appreciate the necessity of striking a balance between theory and practical application. Our philosophy involves being pragmatic in implementation while upholding an approach that is rigorous, defensible, and ultimately solves the intended problem.",
    "Choose RIPE as your trusted solutions partner, where we are dedicated to navigating the intricate landscape of business challenges with precision and expertise.",
  ],
};

export const WhyChooseUsData = [
  {
    heading: "Rapid Expertise Across Functional Domains",
    discription:
      "Navigating the complex landscapes of government and private sectors requires a multidimensional skill set. At RIPE, we bring a wealth of experience in diverse functional areas, allowing us to swiftly adapt to the intricacies of your organisation. Our team's ability to get up to speed quickly ensures that our insights and solutions are not just timely but deeply informed, adding immediate value to your organisation’s projects and operations.",
  },
  {
    heading: "Roll-Up-Your-Sleeves Commitment",
    discription:
      "We don't just offer advice; we actively engage in the process. Our consultants aren't afraid to roll up their sleeves and work alongside your teams to implement strategies, optimise processes, and deliver project results. This hands-on approach distinguishes us as a trusted advisory partner committed to our recommendations, ensuring that your organisation doesn't just plan for success but achieves it.",
  },
  {
    heading: "Foresight Through Experience",
    discription:
      "Experience is the compass that guides us through the intricate landscapes of operations, capital projects and transformation programs. Our deep experience equips us with foresight, allowing us to ask the right questions and mitigate risks well before they materialise. By foreseeing challenges and opportunities, we pave the way for smoother project executions and program transformations, ensuring your initiatives are set up for success from the outset.",
  },
  {
    heading: "Pioneering Innovation",
    discription:
      "Innovation isn't just a feature of our approach; it's the bedrock upon which we build success. We take time to identify the ‘real’ business problems and then ideate and craft innovative solutions. Our steadfast dedication to innovation guarantees that we don't just resolve current challenges but also unearth concealed prospects for growth and enhancement .",
  },
];

// emailToPerson
export const emailToPerson = (values) => {
  return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
      body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        margin: 0;
        padding: 0;
      }
  
      .email-container {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
  
      .header {
        text-align: center;
        margin-bottom: 20px;
      }
  
      .logo {
        max-width: 150px;
        height: auto;
      }
  
      .thank-you-message {
        text-align: center;
      }
  
      .content {
        margin-top: 30px;
      }
    </style>
  </head>
  <body>
    <div class="email-container">
      <div class="header">
        <a href="https://ripeadvisory.com.au/" target="_blank"><img class="logo" src="https://ripeadvisory.com.au/static/media/logo.689a22ecc49b38785ec9.png" alt="richardzanner Logo"></a>
      </div>
      <div class="thank-you-message">
      <h1>Hello,${values.name} !!</h1>
        <h4>Thank You for Contacting Us!</h4>
        <p>We appreciate your interest. Our team will review your message and contact you shortly.</p>
      </div>
      <div class="content">
        <p>If you have any urgent questions or need immediate assistance, please feel free to call us at <strong>+(610) 458 601 533</strong>.</p>
      </div>
    </div>
  </body>
  </html>
      `;
};

//emailFunction for company team
export const emailFunction = (values) => {
  return `<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
      body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        margin: 0;
        padding: 0;
      }
  
      .email-container {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
  
      .header {
        text-align: center;
        margin-bottom: 20px;
      }
  
      .logo {
        max-width: 150px;
        height: auto;
      }
  
      .query-details {
        margin-top: 30px;
        border: 1px solid #ccc;
        padding: 20px;
        background-color: #f7f7f7;
      }
  
      .query-item {
        margin-bottom: 15px;
      }
  
      .query-item label {
        font-weight: bold;
      }
    </style>
  </head>
  <body>
    <div class="email-container">
      <div class="header">
      <a href="https://ripeadvisory.com.au/" target="_blank"><img class="logo" src="https://ripeadvisory.com.au/static/media/logo.689a22ecc49b38785ec9.png" alt="richardzanner Logo"></a>
      </div>
      <div class="query-details">
        <h2>New Query Received !</h2>
        <div class="query-item">
          <label>Name:</label>
          <p>${values.name}</p>
        </div>
        <div class="query-item">
          <label>Email:</label>
          <p>${values.email}</p>
        </div>
        <div class="query-item">
        <label>Phone:</label>
        <p>${values.phone}</p>
      </div> 
      <div class="query-item">
      <label>Solution:</label>
      <p>${values.select}</p>
      </div>
      <div class="query-item">
      <label>Message:</label>
      <p>${values.message}</p>
      </div>
      </div>
    </div>
  </body>
  </html>
      `;
};

