import React from 'react'
import { Page } from '../../layout'
import { PrivacyPolicy, TopBanner } from '../../componets'
import {  Privacyimg } from '../../assets'


export const Privacy = () => {
  return (
    <Page>
    <TopBanner  bannerImage={Privacyimg} sectionsName={"PRIVACY POLICY"} />
      <PrivacyPolicy />
    </Page>
  )
}

