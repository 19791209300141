import React from "react";
import { Rounded1, Rounded2, TelephoneIcon } from "../../assets";
import { motion } from "framer-motion";

export const ContactCard = () => {
  return (
    <div className="my-16">
      <div className=" relative px-4 py-12 tablet:p-0 tablet:h-250 w-11/12 tablet:w-10/12  bg-primary mx-auto  rounded-10">
        <div className="flex w-full flex-col tablet:flex-row items-center h-full">
          <div className="w-full tablet:w-1/2 z-20">
            <motion.h3
              initial={{ x: -30 }}
              animate="animate"
              whileInView={{ x: 0 }}
              transition={{ duration: 0.6, ease: "easeIn" }}
              className="tablet:w-9/12 ml-auto z-20 text-xl tablet:text-2xl text-center tablet:text-start not-italic font-bold text-white"
            >
              Connect with us for
              <br />a free quote or expert help.
            </motion.h3>
          </div>
          <div className="w-full tablet:w-1/2 flex flex-col items-center tablet:flex-row tablet:align z-20  gap-4">
            <motion.a
              initial={{ y: 30 }}
              animate="animate"
              whileInView={{ y: 0 }}
              transition={{ duration: 0.6, ease: "easeIn" }}
              href="mailto:info@ripeadvisory.com"
              target="_blank"
              className="flex w-48 h-12 justify-center items-center bg-gray-900 py-3 px-5 rounded-lg text-white"
            >
              {" "}
              Contact Us
            </motion.a>
            <motion.a
              initial={{ y: 30 }}
              animate="animate"
              whileInView={{ y: 0 }}
              transition={{ duration: 0.6, ease: "easeIn" }}
              href="tel:+61458601533"
              className="flex w-48 tablet:w-56 space-x-1.5 h-12 justify-center items-center py-3 px-5 bg-white rounded-lg"
            >
              <img src={TelephoneIcon} alt="tel" loading="lazy" />
              <span className="text-primary text-sm not-italic font-bold leading-6 rounded-lg">
                +61 458 601 533
              </span>
            </motion.a>
          </div>
          <div className="absolute bottom-0 left-0">
            <img src={Rounded1} alt="" loading="lazy" />
          </div>
          <div className="absolute top-0 right-0">
            <img src={Rounded2} alt="" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  );
};
