import React from "react";
import { blogData } from "../../constant";
import { useNavigate } from "react-router-dom";
import { UnderLine } from "../underline";
import { motion } from "framer-motion";
import { MdKeyboardArrowRight } from "react-icons/md";

export const BlogSection = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white">
      <div className="w-11/12 tablet:w-10/12 mx-auto py-16">
        <motion.div
          initial={{ x: -30, opacity: 0 }}
          animate="animate"
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
        >
          <p className="text-primary text-start tablet:text-center text-base tablet:text-xl not-italic font-semibold leading-6">
            Latest Update
          </p>
          <h5 className="text-gray-900 text-start tablet:text-center not-italic font-bold leading-6 text-2xl tablet:text-40">
            News & Media
          </h5>
        </motion.div>
        <div className="flex justify-start tablet:justify-center my-8">
          <UnderLine />
        </div>
        <motion.div className="flex flex-wrap justify-center desktop:flex-nowrap desktop:flex-row gap-4 my-12 desktop:h-500">
          {blogData.map((blog, index) => {
            return (
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate="animate"
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
                whileHover={{
                  shadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                key={index}
                className="bg-white w-full tablet:w-2/5 desktop:w-auto border pb-4 border-solid rounded-lg px-4 pt-4 flex flex-col space-y-3 items-start hover:shadow-2xl"
              >
                <div className="w-full">
                  <img
                    src={blog.image}
                    alt={`blog${index}`}
                    className="rounded-lg w-full h-54 tablet:h-72 object-cover"
                  />
                </div>
                <div className="p-2 space-y-3 flex items-start flex-col">
                  <div className="flex flex-row items-center space-x-2">
                    <p className="m-0 text-textColor text-sm not-italic font-medium leading-6">
                      {/* {blog.date.toUpperCase()} */}
                    </p>
                  </div>
                  <h3 className="text-black  text-base tablet:text-lg text-start not-italic font-semibold leading-7">
                    {blog.heading}
                  </h3>
                  <div
                    className="flex gap-2 cursor-pointer"
                    onClick={() => navigate(`/blog/${blog.id}`)}
                  >
                    <div>
                      <div className="bg-black rounded-full h-8 w-8 flex justify-center items-center">
                        <MdKeyboardArrowRight className="text-white" />
                      </div>
                    </div>
                    <div className="mt-1">
                      <div className="text-base font-bold text-textColor ">
                        Read More
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};
