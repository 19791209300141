import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const pathPush = useNavigate();
  return (
    <div className="mt-20 mb-20">
      <h1 className="text-gray-900 text-center not-italic font-semibold leading-6 text-2xl tablet:text-40">
        Error 404 Page not found
      </h1>

      <div className="flex justify-center">
        <div
          onClick={() => pathPush("/")}
          className="text-white bg-primary cursor-pointer rounded-sm py-2 px-4 md:text-sm xl:leading-5 xl:text-base whitespace-nowrap font-semibold w-52"
        >
          BACK TO HOMEPAGE
        </div>
      </div>
    </div>
  );
};

export default Error404;
