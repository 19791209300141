import { Route, Routes } from "react-router-dom";
import "./App.css";
import {
  AboutPage,
  ContactPage,
  Home,
  OurApproachPage,
  SolutionsPage,
  Error,
  BlogPage,
  Blog,
} from "./pages";
import { Privacy } from "./pages";

function App() {
  return (
    <div className="App overflow-hidden">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/solutions" element={<SolutionsPage />} />
        <Route path="/ourApproach" element={<OurApproachPage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route path="/contactus" element={<ContactPage />} />
        <Route path="*" element={<Error />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </div>
  );
}

export default App;
