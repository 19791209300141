import React from "react";
import { OurFeature, TopBanner } from "../../componets";
import { aboutBanner } from "../../assets";
import { Page } from "../../layout";

export const OurApproachPage = () => {
  return (
    <Page>
      <TopBanner bannerImage={aboutBanner} sectionsName={"Our Approach"} />
      <OurFeature />
    </Page>
  );
};
