import React from "react";
import { Footer, Navbar } from "../componets";

export const Page = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};
