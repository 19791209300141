import React from "react";
import { Banner, BlogSection, OurFeature, OurServices, WhyChooseUs } from "../../componets";
import { Page } from "../../layout";


export const Home = () => {
    return (
        <Page>
            <Banner />
            <OurFeature />
            <WhyChooseUs />
            <OurServices />
            <BlogSection />
        </Page>
    )
}
