import React from "react";
import { featureData } from "../../constant";
import { UnderLine } from "../underline";
import { motion } from "framer-motion";

export const OurFeature = () => {
  return (
    <div className="bg-lightBackgroudGray py-16 font-poppins">
      <div className="w-11/12 tablet:w-10/12  mx-auto ">
        <motion.div
          initial={{ x: 50 }}
          animate="animate"
          whileInView={{ x: 0 }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          <p className="text-primary text-start tablet:text-center text-base tablet:text-xl not-italic font-semibold leading-6">
            OUR FEATURES
          </p>
          <h5 className="text-gray-900 text-start tablet:text-center not-italic font-semibold leading-6 text-2xl tablet:text-40">
            What makes us different?
          </h5>
        </motion.div>
        <div className="flex justify-start tablet:justify-center my-8">
          <UnderLine />
        </div>
        <div className="flex justify-center flex-wrap gap-4 mt-12 tablet:mx-0">
          {featureData.map((feat, index) => {
            return (
              <motion.div
                initial={{ y: 80 }}
                animate="animate"
                whileInView={{ y: 0 }}
                transition={{ duration: 0.2, ease: "linear" }}
                whileHover={{ y: -10 }}
                key={index}
                className="w-full transition-all duration-[0.1s] ease-[ease-in-out]  desktop:w-340 largeDesktop:w-380 flex bg-white  flex-col desktop:items-start items-center py-8 px-6 space-y-4 desktop:h-400 rounded-lg group hover:bg-featureText hover:bg-no-repeat hover:bg-cover  hover:text-white bg-blend-darken hover:bg-hoverColor"
              >
                <div className="w-20 h-20 group-hover:bg-white group-hover:h-115 group-hover:w-115 group-hover:p-4 group-hover:rounded-full flex justify-center items-center">
                  <img
                    src={feat.image}
                    alt={`team${index}`}
                    className="w-full h-full bg-cover group-hover:h-65 group-hover:w-63"
                  />
                </div>
                <h4 className="text-black text-2xl not-italic font-semibold leading-6 capitalize group-hover:text-white">
                  {feat.heading}
                </h4>
                <p className="text-start desktop:text-start text-textColor text-base not-italic font-normal leading-7 group-hover:text-white">
                  {feat.discription}
                </p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
