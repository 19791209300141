import { Page } from "../../layout";
import Error404 from "../../componets/404-error";

export const Error = () => {
  return (
    <Page>
      <Error404 />
    </Page>
  );
};
