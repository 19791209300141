import React from "react";
import { UnderLine } from "../underline";
import { WhyChooseUsData } from "../../constant";
import { motion } from "framer-motion";

export const WhyChooseUs = () => {
  return (
    <div className="bg-white font-poppins">
      <div className="flex justify-center tablet:px-0 px-10">
        <div className="w-full desktop:w-1/2 flex flex-col items-start mt-14">
          <div className="my-8 space-y-4">
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              animate="animate"
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.7, ease: "easeInOut" }}
            >
              <p className="text-gray-900 text-start tablet:text-center text-xl tablet:text-40 not-italic font-semibold leading-6">
                Why Choose RIPE Advisory?
              </p>
              <div className="flex justify-start tablet:justify-center mb-5">
                <UnderLine />
              </div>
              <p className="text-start tablet:text-center text-serviceText text-sm  not-italic font-normal leading-5">
                Choosing an advisory partner is a strategic decision that can
                redefine the trajectory of your organisation. At RIPE Advisory,
                we offer a unique blend of expertise, hands-on commitment,
                foresight, and innovation that sets us apart in the realm of
                government and private sector advisory.
              </p>
            </motion.div>
          </div>
        </div>
      </div>
      <motion.div className="w-11/12 tablet:w-10/12 phone:w-11/12 mx-auto desktop:px-24 ">
        {WhyChooseUsData.map((why, index) => {
          let indexValue = index + 1;
          return (
            <motion.div
              initial={{ y: -200, opacity: 0 }}
              animate="animate"
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.7, ease: "easeInOut" }}
              key={indexValue}
              className="scroll-animation"
            >
              <div className="flex flex-row items-center space-x-3">
                <div className="flex items-center justify-center text-white rounded-full bg-slides w-8 h-8 tablet:w-10 tablet:h-10 phone:w-8 phone:h-8">
                  {indexValue}
                </div>
                <p className="m-0 text-gray-900 text-left items-start not-italic font-semibold leading-6 text-xs phone:text-lg tablet:text-22">
                  {why.heading}
                </p>
              </div>
              <div className="flex flex-row space-x-6 phone:space-x-6 tablet:space-x-9 items-center">
                <div
                  className={`${
                    indexValue === 4 && "hidden"
                  } border-dashed border-r-2 h-40 phone:h-20 tablet:h-20  border-lightgray w-16  tablet:w-12 phone:w-10 desktop:w-10 mt-4 mb-2 ms-2`}
                ></div>
                <div
                  className={`text-start text-serviceText text-sm tablet:pb-4  phone:text-xs tablet:text-sm not-italic font-normal  mt-2 mb-2 phone:pb-0 phone:mt-0 leading-5 ${
                    indexValue === 4 && "ps-4 phone:ps-5 tablet:ps-4"
                  }`}
                >
                  {why.discription}
                </div>
              </div>
            </motion.div>
          );
        })}
        <motion.p
          initial={{ y: -200, opacity: 0 }}
          animate="animate"
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          className="text-start text-serviceText text-sm italic font-normal leading-5 ms-8 phone:ms-10 tablet:ms-12 ps-1 phone:ps-1  tablet:ps-1  mt-4 mb-0"
        >
          Choose RIPE for a partnership that goes beyond conventional advisory
          services. Our rapid expertise, hands-on commitment, foresight, and
          innovative mindset converge to create a unique advisory experience
          that propels your organisation towards unparalleled success
        </motion.p>
      </motion.div>
    </div>
  );
};
