import React from "react";

export const UnderLine = () => {
  return (
    <div className="flex flex-row space-x-3">
      <div className="h-0.5 w-37 bg-gray-700" />
      <div className="h-0.5 w-63 bg-gray-700" />
    </div>
  );
};
