import React from "react";
import { motion } from "framer-motion";

export const TopBanner = ({ bannerImage, sectionsName }) => {
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${bannerImage})` }}
        className={`relative block  bg-no-repeat bg-cover z-[1] bg-center	 before:absolute before:h-full before:w-full before:content-[''] before:bg-bannerLight before:z-[-1] before:left-0 before:top-0`}
      >
        <div className="bg-gradient pt-147px pb-140px px-0">
          <div className="w-11/12 tablet:w-10/12  m-auto">
            <div className="relative block text-start z-[3]">
              <motion.h2
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeIn" }}
                className="animated animated text-4xl desktop:text-6xl text-white leading-[0.8em] font-extrabold capitalize"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
              >
                {sectionsName}
              </motion.h2>
            </div>
          </div>
          <motion.ul
            initial={{ x: -50, opacity: 0 }}
            animate="animate"
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeIn" }}
            className="absolute bottom-0 px-8 py-2 mb-0 rounded-t-13   bg-white right-20  animated animated"
            data-wow-delay="0.3s"
            data-wow-duration="1500ms"
          >
            <li className="relative inline-block text-xl font-medium capitalize transition-all duration-[0.5s] ease-[ease]">
              <a
                href="/"
                className="text-primary text-base not-italic font-medium leading-8 duration-[0.5s] ease-[ease]"
              >
                Home /
              </a>
            </li>
            <li className="relative inline-block text-xl font-medium capitalize transition-all duration-[0.5s] ease-[ease] ml-2"></li>
            <li className="relative  inline-block text-secondry text-base not-italic font-medium leading-8  transition-all duration-[0.5s] ease-[ease]">
              {sectionsName}
            </li>
          </motion.ul>
        </div>
      </div>
    </div>
  );
};
