import React, { useState } from "react";
import { UnderLine } from "../underline";
import { contactData, emailFunction, emailToPerson } from "../../constant";
import "react-phone-number-input/style.css";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import { selectOptions } from "../../constant";
import { Email } from "../../constant/SMTP";

export const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    select: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const emailRegex = (emailAddress) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(emailAddress);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      emailRegex(formData.email) === false ||
      formData.phone === "" ||
      formData.select === "" ||
      formData.message === ""
    ) {
      setError(true);
      setSubmitted(false);
      return null;
    }
    setError(false);
    setSubmitted(true);
    Email.send({
      To: formData.email,
      send_to: "Customer",
      Subject: "Thank you",
      Body: emailToPerson(formData),
    })
      .then((response) => {
        toast.success("Email sent successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        toast.error("Failed to send email!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    Email.send({
      send_to: "Admin",
      To: "admin_mail",
      Subject: "New Query Received!",
      Body: emailFunction(formData),
    });

    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
      select: "",
    });
  };

  return (
    <div className="pt-20 bg-white">
      <div className=" w-11/12 tablet:w-10/12 mx-auto flex justify-between desktop:flex-row sm:flex-col phone:flex-col flex-col">
        <div className="desktop:w-1/2 sm:w-full phone:w-full md:p-0 sm:p-10 ">
          <motion.h1
            initial={{ y: 100, opacity: 0 }}
            animate="animate"
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeIn" }}
            className=" text-start text-primary"
          >
            Contact us
          </motion.h1>
          <motion.h1
            initial={{ y: 100, opacity: 0 }}
            animate="animate"
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeIn" }}
            className=" lg:text-4xl md:text-3xl phone:text-xl text-start text-footerColor leading-10 w-custom95 largeDesktop:w-85p"
          >
            We're looking forward to partnering with you
          </motion.h1>
          <div className="mb-7 mt-4">
            <UnderLine />
          </div>
          <motion.p
            initial={{ y: 100, opacity: 0 }}
            animate="animate"
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeIn" }}
            className=" text-contacttextColor text-sm desktop:text-base text-start w-custom95 largeDesktop:w-85p"
          >
            Choose RIPE as your trusted solutions partner, where we are
            dedicated to navigating the intricate landscape of business
            challenges with precision and expertise.
          </motion.p>
          <div className="mt-10">
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate="animate"
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6, ease: "easeIn" }}
              className="phone:flex flex-wrap flex justify-start tablet:justify-between sm:flex-row phone:flex-row w-full mt-4 gap-5"
            >
              {contactData.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="flex gap-2 space-x-2 w-full desktop:w-48p"
                >
                  <img
                    className="lg:w-11 lg:h-11 md:w-10 md:h-10 phone:w-8 phone:h-8 h-8 w-8"
                    src={item.icon}
                    alt="icon"
                    loading="lazy"
                  />
                  <div>
                    <h1 className="text-[#313131] font-bold text-start md:text-sm desktop:text-base">
                      {item.title}
                    </h1>
                    <p className="text-sm text-ContacticonPText text-start flex-wrap">
                      {item.content1} <br />
                      {item.content2}
                    </p>
                  </div>
                </a>
              ))}
            </motion.div>
          </div>
        </div>
        <div className="desktop:w-1/2 sm:w-full phone:w-full md:mt-0 sm:mt-5 phone:mt-5 bg-ContactformBgColor rounded-2xl">
          <motion.form
            initial={{ y: 100, opacity: 0 }}
            animate="animate"
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeIn" }}
            onSubmit={handleSubmit}
            className="p-5 largePhone:p-10 space-y-6"
          >
            <div className="flex justify-between gap-5 w-full phone:flex-col sm:flex-row flex-col">
              <div className="sm:w-1/2 phone:w-full">
                <input
                  className=" border-b border-contactBorder  bg-ContactformBgColor   w-full py-2 px-3  sm:mb-3 phone:mb-0 leading-tight focus:outline-none placeholder-ContactPlacholderColor"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Name *"
                />
              </div>
              <div className="sm:w-1/2 phone:w-full">
                <input
                  className=" border-b border-contactBorder  bg-ContactformBgColor    w-full py-2 px-3  sm:mb-3 phone:mb-0 leading-tight focus:outline-none placeholder-ContactPlacholderColor"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email *"
                />
              </div>
            </div>
            <div className="flex justify-between gap-5 sm:mt-1 phone:mt-4 w-full phone:flex-col flex-col sm:flex-row">
              <div className="sm:w-1/2 phone:w-full">
                <select
                  className=" border-b border-contactBorder bg-ContactformBgColor w-full py-2 px-3 sm:mb-3 phone:mb-0 leading-tight focus:outline-none placeholder-ContactPlacholderColor"
                  name="select"
                  value={formData.select}
                  onChange={handleInputChange}
                >
                  <option hidden className="placeholder-white">
                    Select Solution
                  </option>
                  {selectOptions.map((e, i) => (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sm:w-1/2 phone:w-full">
                {/* <PhoneInput
                  placeholder="Phone number"
                  name="phone"
                  value={formData.phone}
                  onChange={(val) => setFormData({ ...formData, phone: val })}
                  className="contact-input"
                  defaultCountry="AU"
                /> */}
                <input
                  className=" border-b border-contactBorder  bg-ContactformBgColor    w-full py-2 px-3  sm:mb-3 phone:mb-0 leading-tight focus:outline-none placeholder-ContactPlacholderColor"
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone number"
                />
              </div>
            </div>
            <div className=" sm:mt-1 phone:mt-4 w-full">
              <div>
                <textarea
                  className=" border-b border-contactBorder  bg-ContactformBgColor w-full py-6 px-3  sm:mb-3 phone:mb-0 leading-tight focus:outline-none placeholder-ContactPlacholderColor placeholder-py-4 resize-none"
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Message"
                  rows={5}
                />
              </div>
            </div>
            {error && (
              <div className="sm:mt-1 phone:mt-1 w-full font-bold text-start text-errorRed">
                Please fill form correctly
              </div>
            )}
            <div className="flex items-start justify-start sm:mt-1 phone:mt-4">
              <button
                className="bg-primary text-white w-full py-4 text-center rounded-full"
                type="submit"
              >
                Send Enquiry
              </button>
            </div>
          </motion.form>
        </div>
      </div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate="animate"
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1, ease: "easeIn" }}
        className="mt-8"
      >
        <iframe
          height="450"
          loading="lazy"
          allowFullScreen
          title="contact-page-map"
          className="w-full"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4686.922707335912!2d151.207118183671!3d-33.83411755164268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae911b717f3b%3A0x5017d681632c330!2sNeutral%20Bay%20NSW%202089%2C%20Australia!5e0!3m2!1sen!2sin!4v1706878957494!5m2!1sen!2sin"
        ></iframe>
      </motion.div>
      <Toaster />
    </div>
  );
};
