import React from "react";
import { motion } from "framer-motion";
import { FooterLogo, Linkedin } from "../../assets";
import { Link } from "react-router-dom";

export const Footer = () => {
  const footerdata = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Us",
      link: "/aboutus",
    },
    {
      name: "Contact Us",
      link: "/contactus",
    },
    {
      name: "Privacy Policy",
      link: "/privacy",
    },
  ];

  const linkedIn = {
    icon: Linkedin,
    link: "https://www.linkedin.com/in/richard-zanner/",
    altName: "Linkedin",
  };

  return (
    <div className="bg-black pt-16">
      <div className="">
        <div className="space-y-10 flex flex-col justify-center items-center w-11/12 tablet:w-full m-auto">
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate="animate"
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeIn" }}
          >
            <img src={FooterLogo} alt="" loading="lazy" />
          </motion.div>
          <div className="flex flex-col items-center tablet:flex-row tablet:space-x-8">
            {footerdata.map((data, index) => {
              return (
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate="animate"
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeIn" }}
                  key={index}
                >
                  <Link
                    to={data.link}
                    className="text-gray-200 text-base not-italic font-normal leading-7 hover:text-primary"
                  >
                    {data.name}
                  </Link>
                </motion.div>
              );
            })}
          </div>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate="animate"
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, ease: "easeIn" }}
            className="flex flex-row space-x-8"
          >
            <a
              href={linkedIn.link}
              className="bg-stone-400 h-8 flex justify-center items-center w-8 rounded-lg hover:bg-white"
            >
              <img
                src={linkedIn.icon}
                alt={linkedIn.altName}
                className="fill-white text-white"
                loading="lazy"
              />
            </a>
          </motion.div>
          <div className="bg-gray-800 h-px w-full" />
        </div>
        <div className="m-0 py-4">
          <motion.p
            initial={{ y: 10, opacity: 0 }}
            animate="animate"
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeIn" }}
            className="text-gray-600 text-base not-italic font-normal leading-6 m-0"
          >
            Copyright © 2023 RIPE Advisory - All Rights Reserved.
          </motion.p>
        </div>
      </div>
    </div>
  );
};
