import React from "react";
import { UnderLine } from "../underline";
import { AboutRipe } from "../../assets";
import { aboutRipe } from "../../constant";
import { motion } from "framer-motion";

export const Ripeadvisory = () => {
  return (
    <div className="w-11/12 tablet:w-10/12 py-16  mx-auto ">
      <div className="flex flex-col desktop:flex-row justify-center items-center">
        <div className="w-full desktop:w-1/2">
          <div className="border-4 tablet:border-11 border-primary desktop:w-400 largeDesktop:w-450 tablet:h-600   border-solid relative">
            <div className="desktop:absolute w-full desktop:w-450 largeDesktop:w-500 left-8 h-full">
              <motion.img
                initial={{ y: 100 }}
                animate="animate"
                whileInView={{ y: 0 }}
                transition={{ duration: 0.6, ease: "easeIn" }}
                src={AboutRipe}
                alt="about-ripe"
                className="w-full h-full tablet:py-6"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="w-full desktop:w-1/2 flex flex-col items-start mt-10 desktop:mt-0">
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "linear" }}
          >
            <p className="text-gray-600 text-base tablet:text-xl not-italic font-semibold leading-6 mb-0">
              Welcome To RIPE Advisory
            </p>
            <h3 className="text-gray-900 mt-3 text-start text-2xl tablet:text-4xl not-italic font-semibold">
              About Us
            </h3>
            <UnderLine />
          </motion.div>
          <div className="text-textColor py-4 text-justify text-sm lg:text-base not-italic font-normal leading-7">
            {aboutRipe.content.map((p, i) => (
              <motion.p
                initial={{ x: -80, opacity: 0 }}
                animate="animate"
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeIn" }}
                key={i}
                className={i === 3 ? "italic" : "font-poppins"}
              >
                {p}
              </motion.p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
