import React from "react";
import { motion } from "framer-motion";
import { HomeBanner } from "../../assets";

export const Banner = () => {
  return (
    <div
      style={{ backgroundImage: `url(${HomeBanner})` }}
      className={`relative block  bg-no-repeat bg-cover py-16 sm:h-[90vh] w-full"`}
    >
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate="animate"
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeIn" }}
        className="text-white tablet:w-10/12 flex justify-end tablet:justify-end pr-12 sm:pr-0 items-center h-full text-lg sm:text-40 leading-normal  tablet:text-55 desktop:text-65 text-right not-italic font-bold uppercase banner-textShadow"
      >
        NAVIGATE
        <br /> TOWARDS
        <br />
        SUCCESS
      </motion.div>
    </div>
  );
};
