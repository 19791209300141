import React from "react";
import { ContactUsPage, TopBanner } from "../../componets";
import { Page } from "../../layout";
import { ContactBanner } from "../../assets";

export const ContactPage = () => {
  return (
    <Page>
      <TopBanner bannerImage={ContactBanner} sectionsName={"Contact us"} />
      <ContactUsPage />
    </Page>
  );
};
