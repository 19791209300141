import { Form } from "../Form";
import { UnderLine } from "../underline";
import Slider from "react-slick";
import { next, prev, home } from "../../assets";
import { solutionCardDetail } from "../../constant";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

export const OurServices = () => {
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img
        className={`${className} !h-8 !w-8 desktop:!h-9 desktop:!w-9`}
        src={next}
        alt="arrow"
        onClick={onClick}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img
        className={`${className} !h-8 !w-8 rotate-180 desktop:!h-9 desktop:!w-9`}
        src={prev}
        alt="arrow"
        onClick={onClick}
      />
    );
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    fade: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: false,
    adaptiveHeight: true,
    dotsClass: "slick-dots",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="bg-white py-16 font-poppins">
      <div className="flex flex-wrap justify-center mb-44 desktop:mb-44">
        <motion.div
          initial={{ x: 10, opacity: 0 }}
          animate="animate"
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="flex flex-wrap flex-col desktop:flex-row desktop:flex-nowrap desktop:space-x-5 w-4/5"
        >
          <div className="w-full desktop:w-2/5 mediumDesktop:w-1/3 desktop:ms-7">
            <p className="text-gray-600 text-base text-start tablet:text-xl not-italic font-semibold leading-6">
              OUR SERVICES
            </p>
            <h5 className="text-gray-900 not-italic text-start font-semibold leading-6 text-xl tablet:text-4xl">
              Excellent service and support for you{" "}
            </h5>
            <div className="mb-5 mt-10">
              <UnderLine />
            </div>
          </div>
          <div className="w-full desktop:w-3/5 mediumDesktop:w-2/3">
            <p className="text-start text-serviceText text-sm desktop:text-base not-italic font-normal leading-5 mt-4 desktop:mt-14">
              We have a wide range of service concentrations under which we will
              tailor and combine to your unique requirements. From Project
              Health Checks and Risk Reviews to Leading Integration of Business
              Units and Developing Asset Registers, at RIPE we have you covered.
            </p>
          </div>
        </motion.div>
      </div>
      <div className="bg-primary py-28 relative carousel">
        <div className="flex justify-center -mt-52 tablet:-mt-56 desktop:-mt-60">
          <div className="w-4/5">
            <motion.div
              // initial={{ y: 200, opacity: 0 }}
              // animate="animate"
              // whileInView={{ y: 0, opacity: 1 }}
              // transition={{ duration: 1, ease: "easeInOut" }}
              className="w-full"
            >
              <Slider {...settings}>
                {solutionCardDetail.map((card, i) => {
                  return (
                    <div
                      key={i}
                      className="text-start space-y-6 bg-white rounded-t-xl flex justify-center items-center gap-2 custom pb-8"
                    >
                      <div className="w-full relative tablet:h-48 desktop:h-60 overflow-hidden rounded-t-10">
                        <img
                          src={card.image}
                          alt="carousel"
                          className="w-full h-full object-cover object-center scale-105 "
                          loading="lazy"
                        />
                      </div>
                      <div className="px-4 pt-2">
                        <h3 className="text-lg tablet:text-xl desktop:text-22 largeDesktop:text-25 font-semibold text-slides leading-6 whitespace-nowrap">
                          {card.heading?.length > 20
                            ? `${card.heading?.substring(0, 20)} ...`
                            : card.heading}
                        </h3>
                        <p className="w-full  largeDesktop:h-24 h-28 text-start text-serviceText text-xs desktop:text-sm not-italic font-normal leading-5 ">
                          {card.content[0]?.length > 70
                            ? `${card.content[0]?.substring(0, 200)} ...`
                            : card.content[0]}
                        </p>

                        <HashLink
                          smooth
                          to={`/solutions#${card.id}`}
                          className="text-primary underline font-bold text-xs tablet:text-base"
                        >
                          Read more
                        </HashLink>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </motion.div>
          </div>
        </div>
        <div className="w-11/12 tablet:w-3/4  mx-auto relative mt-10">
          <div className="w-full relative py-12 flex flex-col desktop:flex-row gap-8">
            <div className="w-full desktop:w-1/2">
              <Form formText="Contact us today to discuss your organisation's advisory needs." />{" "}
            </div>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              animate="animate"
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className="w-full desktop:w-1/2"
            >
              <div className="relative">
                <div className="h-full flex justify-end relative w-full z-20">
                  <img
                    src={home}
                    alt="servicesImage1"
                    className="z-20 w-full h-full desktop:w-auto"
                    loading="lazy"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
