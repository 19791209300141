import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import { emailFunction, emailToPerson, selectOptions } from "../../constant";
import { Email } from "../../constant/SMTP";

export const Form = ({ formText }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    select: "",
    message: "",
    phone: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const emailRegex = (emailAddress) => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(emailAddress);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      emailRegex(formData.email) === false ||
      formData.select === "" ||
      formData.message === "" ||
      formData.phone === ""
    ) {
      setError(true);
      setSubmitted(false);
      return null;
    }
    console.log(formData, "formData");
    setError(false);
    setSubmitted(true);
    Email.send({
      To: formData.email,
      send_to: "Customer",
      Subject: "Thank you",
      Body: emailToPerson(formData),
    })
      .then((response) => {
        toast.success("Email sent successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        toast.error("Failed to send email!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    Email.send({
      send_to: "Admin",
      To: "admin_mail",
      Subject: "New Query Received!",
      Body: emailFunction(formData),
    });

    setFormData({
      name: "",
      email: "",
      message: "",
      select: "",
      phone: "",
    });
  };

  return (
    <div className="w-full h-full space-y-7">
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate="animate"
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
      >
        <h1 className=" text-start text-base text-white">{formText}</h1>
        <h1 className=" md:text-4xl phone:text-xl text-start text-white !mt-3">
          Get tailored business <br /> advisory solutions
        </h1>
      </motion.div>
      <div className="flex gap-1 mb-7 mt-4">
        <div className=" w-3 h-0.5 rounded-full bg-white"></div>
        <div className=" w-10 h-0.5 rounded-full  bg-white"></div>
      </div>
      <motion.form
        initial={{ x: -200, opacity: 0 }}
        animate="animate"
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
        onSubmit={handleSubmit}
        className="space-y-6"
      >
        <div className="flex justify-between gap-5 w-full flex-col phone:flex-col sm:flex-row">
          <div className="sm:w-1/2 phone:w-full">
            <h2 className=" text-start text-white">Name*</h2>
            <input
              className=" border border-borderline placeholder:text-formPlaceholderColor bg-primary  rounded w-full py-2 px-3 text-white placeholder-white sm:mb-3 phone:mb-0 leading-tight focus:outline-none "
              type="text"
              placeholder=""
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="sm:w-1/2 phone:w-full">
            <h2 className=" text-start text-white">Phone*</h2>

            <input
              className=" border border-borderline placeholder:text-formPlaceholderColor bg-primary  rounded w-full py-2 px-3 text-white placeholder-white sm:mb-3 phone:mb-0 leading-tight focus:outline-none "
              type="number"
              placeholder="+61 02 0000 0000"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex justify-between gap-5 sm:mt-1 phone:mt-4 w-full flex-col phone:flex-col sm:flex-row">
          <div className="sm:w-1/2 phone:w-full">
            <h2 className=" text-start text-white">Email Address*</h2>
            <input
              className=" border border-borderline bg-primary  placeholder:text-formPlaceholderColor  rounded w-full py-2 px-3 text-white placeholder-white sm:mb-3 phone:mb-0 leading-tight focus:outline-none "
              type="text"
              placeholder="support@ripeadvisory.com"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="sm:w-1/2 phone:w-full">
            <h2 className=" text-start text-white">Solution</h2>
            <select
              className="border border-borderline bg-primary placeholder:text-formPlaceholderColor rounded w-full py-2 px-3 text-white placeholder-white sm:mb-3 phone:mb-0 leading-tight focus:outline-none"
              name="select"
              value={formData.select}
              onChange={handleInputChange}
            >
              <option
                hidden
                className="placeholder-white text-formPlaceholderColor text-white"
              >
                Select Solution
              </option>
              {selectOptions.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="sm:mt-1 phone:mt-4 w-full">
          <div>
            <h2 className=" text-start text-white">Message</h2>
            <textarea
              className=" border border-borderline bg-primary  rounded w-full py-2 px-3 text-white placeholder-white sm:mb-3 phone:mb-0 leading-tight focus:outline-none resize-none"
              type="text"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              rows={5}
            />
          </div>
        </div>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
            className="sm:mt-1 phone:mt-1 w-full text-errorRed font-semibold text-start"
          >
            Please fill form correctly
          </motion.div>
        )}
        {/* {submitted && (
          <div>
            <p className="font-semibold text-green-500 text-start">
              Form has been submitted successfully
            </p>
          </div>
        )} */}
        <div className="flex items-start justify-start sm:mt-1 phone:mt-4">
          <motion.button
            whileTap={{ scaleX: 0.8 }}
            className="bg-white text-formbtnColor py-2.5 px-7 text-center rounded font-semibold"
            type="submit"
          >
            Get in contact
          </motion.button>
        </div>
      </motion.form>
      <Toaster />
    </div>
  );
};
