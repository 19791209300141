import React from "react";
import { ContactCard, TopBanner } from "../../componets";
import { solutionBg } from "../../assets";
import { Page } from "../../layout";
import { UnderLine } from "../../componets/underline";
import { solutionCardDetail } from "../../constant/index";
import { motion } from "framer-motion";

export const SolutionsPage = () => {
  return (
    <Page>
      <TopBanner bannerImage={solutionBg} sectionsName={"SOLUTIONS"} />
      <div>
        {solutionCardDetail.map((card, i) => {
          const { heading, image, content } = card;
          const isEven = i % 2 !== 0;

          return (
            <section
              key={i}
              id={card.id}
              className={`${!isEven && "bg-solutionPageBg"}`}
            >
              <div className="w-full tablet:w-10/12 py-16 mx-auto">
                <div
                  className={`flex flex-col desktop:flex-row justify-start items-center gap-10 tablet:gap-10 desktop:gap-2 p-3 tablet:p-2 sm:p-7 xl:p-0  ${
                    isEven &&
                    "desktop:flex-row-reverse tablet:space-x-40 tablet:space-x-reverse tablet:justify-between"
                  } `}
                >
                  <div
                    className={`w-full desktop:w-1/2 ${
                      isEven && "desktop:flex desktop:justify-center"
                    }`}
                  >
                    <div
                      className={`border-4 tablet:border-11 border-primary desktop:w-400 largeDesktop:w-450 tablet:h-600 border-solid relative`}
                    >
                      <div
                        className={`desktop:absolute w-full desktop:w-450 largeDesktop:w-500 ${
                          isEven ? "right-8" : "left-8"
                        } h-full`}
                      >
                        <motion.img
                          initial={{ y: 50, opacity: 0 }}
                          animate="animate"
                          whileInView={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.7, ease: "easeIn" }}
                          src={image}
                          alt="services"
                          className="w-full h-full tablet:py-6 p-2 object-cover"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-full desktop:w-5/12 flex flex-col items-start  ${
                      isEven && "!me-0"
                    }`}
                  >
                    <motion.h3
                      initial={{ y: 100, opacity: 0 }}
                      animate="animate"
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.7, ease: "easeIn" }}
                      className="text-footerColor text-start text-2xl largeDesktop:text-3xl not-italic font-semibold"
                    >
                      {heading}
                    </motion.h3>
                    <UnderLine />
                    <div className="text-textColor text-justify py-4 text-sm lg:text-base space-y-2  not-italic font-normal leading-7">
                      {content.map((paragraph, i) => (
                        <motion.p
                          initial={{ y: 100, opacity: 0 }}
                          animate="animate"
                          whileInView={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.7, ease: "easeIn" }}
                          key={i}
                          className="mb-1"
                        >
                          {paragraph}
                        </motion.p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
        <ContactCard />
      </div>
    </Page>
  );
};
