import { useState, useEffect } from "react";
import { Drawer } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../assets";
import { MenuIcon } from "../../assets/menuIcon";
import "./styles.css";
import { motion, useAnimation } from "framer-motion";

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      x: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: "linear" },
    });
  }, [controls]);

  const NavbarItem = [
    {
      name: "Home",
      href: "/",
    },
    {
      name: "About Us",
      href: "/aboutus",
    },
    {
      name: "Solutions",
      href: "/solutions",
    },
    {
      name: "Contact Us",
      href: "/contactus",
    },
  ];

  return (
    <div className="bg-carbon-fiber py-3">
      <div className="w-11/12 mediumDesktop:w-10/12 m-auto">
        <div className="hidden w-full desktop:flex xxs:justify-between  lg:justify-center lg:items-center">
          <div className="w-4/12">
            <ul className="flex flex-row justify-end items-center tablet:gap-12 desktop:gap-20 tracking-wider font-medium m-0">
              <motion.li
                initial={{ x: "-100%", opacity: 0 }}
                animate={controls}
                className={`nav-tem ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                <Link
                  to="/"
                  className="text-black md:text-sm tablet:text-base tablet:leading-5 whitespace-nowrap"
                >
                  Home
                </Link>
              </motion.li>
              <motion.li
                initial={{ x: "-100%", opacity: 0 }}
                animate={controls}
                className={`nav-tem ${
                  location.pathname === "/aboutus" ? "active" : ""
                }`}
              >
                <Link
                  to="/aboutus"
                  className="text-navbartext text-center not-italic leading-5 uppercase whitespace-nowrap"
                >
                  About Us
                </Link>
              </motion.li>
            </ul>
          </div>
          <div className="w-5/12 m-auto">
            <motion.div
              initial={{ x: "-100%", opacity: 0 }}
              animate={controls}
              className="tablet:w-150 desktop:w-172 largeDesktop:w-184 w-16 h-auto m-auto grow desktop:ml-20 largeDesktop:m-auto"
            >
              <a href="/">
                <img src={Logo} alt="logo" loading="lazy" />
              </a>
            </motion.div>
          </div>
          <div className="w-4/12">
            <ul className="flex flex-row justify-end tablet:gap-12 desktop:gap-12 items-center tracking-wider font-medium m-0">
              <motion.li
                initial={{ x: "-100%", opacity: 0 }}
                animate={controls}
                className={`nav-tem ${
                  location.pathname === "/solutions" ? "active" : ""
                }`}
              >
                <Link to="/solutions" className="leading-5 uppercase">
                  Solutions
                </Link>
              </motion.li>
              <motion.li
                initial={{ x: "-100%", opacity: 0 }}
                animate={controls}
                className={`nav-tem ${
                  location.pathname === "/contactus" ? "active" : ""
                }`}
              >
                <Link
                  to="/contactus"
                  className="leading-5 uppercase desktop:whitespace-nowrap"
                >
                  Contact Us
                </Link>
              </motion.li>
              <li>
                <motion.div
                  whileTap={{
                    scale: 0.9,
                  }}
                  onClick={() => navigate("/contactus")}
                  className="text-white bg-primary cursor-pointer font-poppins rounded-sm py-2 px-4 md:text-sm xl:leading-5 xl:text-base whitespace-nowrap font-normal"
                >
                  GET IN CONTACT
                </motion.div>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-between w-full items-center">
          <div className="lg:justify-end desktop:hidden">
            <a href="/">
              <div className="w-28 lg:w-16 h-auto ">
                <img src={Logo} alt="logo" loading="lazy" />
              </div>
            </a>
          </div>
          <div className="flex lg:justify-end desktop:hidden">
            <button onClick={showDrawer}>
              <MenuIcon />
            </button>
            <Drawer
              title="Ripe Advisory"
              className="customeDrower"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <ul className="flex flex-col items-start gap-12 tracking-wider font-semibold">
                {NavbarItem.map((item, index) => {
                  return item.image ? null : (
                    <li className="xl:w-32" key={index}>
                      <Link
                        to={item.href}
                        className={`text-buttonBg md:text-sm  xl:text-base whitespace-nowrap ${
                          location.pathname === item.href &&
                          "text-amber-900 font-bold"
                        }`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};
