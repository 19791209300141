import React from "react";
import { Form } from "../Form";
import { servicesImage1, servicesImage2 } from "../../assets";
import { motion } from "framer-motion";

export const Estimate = () => {
  return (
    <div className="bg-primary py-16">
      <div className="flex  flex-col desktop:flex-row gap-8 w-11/12 tablet:w-10/12  mx-auto py-16">
        <div className="w-full desktop:w-1/2 h-full">
          <Form formText="Let's Chat" />
        </div>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate="animate"
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          className="w-full desktop:w-1/2"
        >
          <div className="relative">
            <div className="h-full flex justify-end relative w-full z-20">
              <img
                src={servicesImage1}
                alt="servicesImage1"
                className="z-20 w-full h-full desktop:w-auto"
                loading="lazy"
              />
            </div>
            <div className="absolute -bottom-16 left-0">
              <img src={servicesImage2} alt="servicesImage2" loading="lazy" />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
